// components
import Utm from "../components/_global/utm";

// images
import TopBackground from "../images/araujoirmaos/araujoirmaos-header.jpeg";
import Dollar from "../images/confi/dollar.svg";
import Globe from "../images/confi/globe.svg";
import Handshake from "../images/confi/handshake.svg";
import CheckIcon from "../images/icon/check.svg";
import Facts from "../images/confi/location.svg";
import Size from "../images/confi/size.svg";
import Profile from "../images/confi/profile.svg";
import Foursource from "../images/confi/foursource.svg";
import Apparel from "../images/confi/apparel.svg";
import ApparelManufacturer from "../images/confi/apparelManufacturer.svg";
import Banner from "../images/confi/banner.svg";
import PedroPeixoto from "../images/araujoirmaos/pedro-peixoto.jpg";


export const pageTexts = (t) => {
  return {
    topInfo: {
      title: {
        text: t("araujoirmaos.topTitle"),
        colorClass: "colorLight",
      },
      subtitle: {
        text: t("araujoirmaos.subTitle"),
        colorClass: "colorLight",
      },
      /* cta: {
        text: t("araujoirmaos.topCta"),
        colorClass: "colorLight",
        url: t("araujoirmaos.ctaUrl"),
        type: "mainColor",
      }, */
      bottomDiagonal: true,
      opacity: true,
      image: {
        src: TopBackground,
        alt: t("araujoirmaos.imgAlt"),
      },
    },
    topIcons: [
      {
        title: {
          text: t("araujoirmaos.newMarketsValue"),
          textBefore: "",
          extAfter: " EUR",
          isNum: false,
        },
        subtitle: {
          text: t("araujoirmaos.newMarketsSubtitle"),
          colorClass: "colorDark",
        },
        icon: {
          src: Globe,
          alt: t("araujoirmaos.newMarketsAlt"),
        },
      },
      {
        title: {
          text: t("araujoirmaos.roiValue"),
          textBefore: " ",
          textAfter: " EUR",
          isNum: false,
        },
        subtitle: {
          text: t("araujoirmaos.roiSubtitle"),
          colorClass: "colorDark",
        },
        icon: {
          src: Dollar,
          alt: t("araujoirmaos.roiAlt"),
        },
      },
      {
        title: {
          text: t("araujoirmaos.recruitingValue"),
          textBefore: "",
          isNum: true,
        },
        subtitle: {
          text: t("araujoirmaos.recruitingSubtitle"),
          colorClass: "colorDark text-center",
        },
        icon: {
          src: Handshake,
          alt: t("araujoirmaos.recruitingAlt"),
        },
      },
    ],
    sideIcons1: [
      {
        title: {
          text: t("araujoirmaos.locationTitle"),
        },
        subtitle: {
          text: t("araujoirmaos.locationSubtitle"),
          colorClass: "colorDarkLight",
        },
        icon: {
          src: Facts,
          alt: t("araujoirmaos.locationAlt"),
        },
      },
      {
        title: {
          text: t("araujoirmaos.productTitle"),
        },
        subtitle: {
          text: t("araujoirmaos.productSubtitle"),
          colorClass: "colorDarkLight",
        },
        icon: {
          src: Apparel,
          alt: t("araujoirmaos.productAlt"),
        },
      },
      {
        title: {
          text: t("araujoirmaos.memberTitle"),
        },
        subtitle: {
          text: t("araujoirmaos.memberSubtitle"),
          colorClass: "colorDarkLight",
        },
        icon: {
          src: ApparelManufacturer,
          alt: t("araujoirmaos.memberAlt"),
        },
      },
      {
        title: {
          text: t("araujoirmaos.membershipTitle"),
        },
        subtitle: {
          text: t("araujoirmaos.membershipSubtitle"),
          colorClass: "colorDarkLight",
        },
        icon: {
          src: Profile,
          alt: t("araujoirmaos.membershipAlt"),
        },
      },
      {
        title: {
          text: t("araujoirmaos.sizeTitle"),
        },
        subtitle: {
          text: t("araujoirmaos.sizeSubtitle"),
          colorClass: "colorDarkLight",
        },
        icon: {
          src: Size,
          alt: t("araujoirmaos.sizeAlt"),
        },
      },
      {
        title: {
          text: t("araujoirmaos.sinceTitle"),
        },
        subtitle: {
          text: t("araujoirmaos.sinceSubtitle"),
          colorClass: "colorDarkLight",
        },
        icon: {
          src: Foursource,
          alt: t("araujoirmaos.sinceAlt"),
        },
      },
    ],
    sideIcons2: [
      {
        title: {
          text: t("araujoirmaos.challengesSquareTitle"),
          textSize: "font24 lineHeight1_3 ",
        },
        subtitle: {
          text: t("araujoirmaos.challengesSquareSubtitle"),
          colorClass: "colorDark",
          textSize: "font17  pt-10"
        },
        icon: {
          src: '',
          alt: t("araujoirmaos.newMarketsAlt"),
        },
      },
    ],
    sideIcons3: [
      {
        title: {
          text: t("araujoirmaos.favoriteToolsTitle"),
          textSize: "font24 lineHeight1_3 ",
        },
        subtitle: {
          text: t("araujoirmaos.favoriteToolsSubtitle"),
          colorClass: "colorDark",
          textSize: " font17 pt-20"
        },
        icon: {
          src: '',
          alt: t("araujoirmaos.favoriteToolsAlt"),
        },
        banner: true,
        bannerSrc: Banner,
        bannerAlt: "Banner"
      },
    ],
    pedroPeixoto: [
      {
        title: {
          text: t("araujoirmaos.pedroName"),
        },
        subtitle: {
          text: t("araujoirmaos.pedroTitle"),
          colorClass: "colorDarkLight",
        },
        icon: {
          src: PedroPeixoto,
          alt: t("araujoirmaos.newMarketsAlt"),
        },
      },
    ],
    aboutInfo: {
      title: {
        text: t("araujoirmaos.aboutTitle"),
        colorClass: "colorDark",
        extraClass: "font40",
      },
      text: {
        text: t("araujoirmaos.aboutText"),
        colorClass: "colorDark",
        textType: "paragraph",
      },
      separator: true,
    },
    challengesInfo: {
      title: {
        text: t("araujoirmaos.challengesTitle"),
        colorClass: "colorDark",
        extraClass: "font40",
      },
      text: {
        text: t("araujoirmaos.challengesText"),
        colorClass: "colorDark",
        textType: "paragraph",
      },
      separator: true,
    },
    findingInfo: {
      title: {
        text: t("araujoirmaos.findingTitle"),
        colorClass: "colorDark",
        extraClass: "font40",
      },
      text: {
        text: t("araujoirmaos.findingText"),
        colorClass: "colorDark",
        textType: "paragraph",
      },
      separator: true,
    },
    resultsInfo: {
      title: {
        text: t("araujoirmaos.resultsTitle"),
        colorClass: "colorDark",
        extraClass: "font40",
      },
      text: {
        text: t("araujoirmaos.resultsText"),
        colorClass: "colorDark",
        textType: "paragraph",
      },
      separator: true,
    },
    bullet: {
      icon: { fontAwesome: false, src: CheckIcon, alt: t("homepage.checkAlt") },
      separator: false,
      item: {
        text: [
          t("araujoirmaos.bullet1"),
          t("araujoirmaos.bullet2"),
          t("araujoirmaos.bullet3"),
        ],
        colorClass: "colorDark",
      },
      customPBText: true,
    },
    bottomText:  [
      {
        text: t("araujoirmaos.bulletBottomText"),
        colorClass: "colorDark",
        textType: "paragraph",
      },
    ],
    cta: {
      text: t("araujoirmaos.cta1"),
      url: "https://foursource.com/apparel-manufacturers/",
      type: "whiteColor",
    },
    cta2: {
      text: t("araujoirmaos.cta2"),
      url: process.env.GATSBY_CHINA === "true" ? `${process.env.APP_CHINA_URL}/signup/` : Utm(`${process.env.APP_URL}/signup/`),
      type: "mainColor",
    },
    squareText: t("araujoirmaos.challengesSquareSubtitle2"),
    favoriteToolsExtra: t("araujoirmaos.favoriteToolsExtra"),
    profileText: t("araujoirmaos.profileText"),
    testimonyName: t("araujoirmaos.pedroName"),
    testimonyTitle: t("araujoirmaos.pedroTitle"),
    anchorText: t("araujoirmaos.anchorText")
  };
};
